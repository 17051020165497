<template>
  <div class="d-flex flex-column align-center">

    <div
      style="width: 100%"
      class="d-flex">

      <h2 class="font-rubik font-weight-medium">Exàmens ordenats per anys</h2>

      <v-spacer></v-spacer>

      <div
        v-if="limit<=years.length && !$vuetify.breakpoint.xs"
        @click="limit< years.length ? limit=years.length : limit = 12"
        class="d-flex justify-center mt-2 align-center"
        style="cursor: pointer"
      >
        <v-icon size="18"
                :style="limit< years.length ? '' : 'transform: rotate(180deg) !important'"
        >
          {{mdiChevronDown}}
        </v-icon>
        <span class="caption ml-1 text-uppercase font-weight-medium" style="color: #6B7280 !important;">
          {{ limit < years.length ? `Mostra'n més` : `Mostra'n menys` }}
        </span>
      </div>

    </div>

    <div style="width: 100%" class="text-start justify-start">
      <span class="font-rubik" style="font-size: 14px; color: #7f8698; font-weight: 500"
      >{{ years.length }} exàmens</span>
    </div>

    <div
      v-if="exams && exams.length > 0"
      :ref="`slide_${refresh}`"
      style="width: 100%"
      class="mt-3"
      :class="$vuetify.breakpoint.xs ? 'd-flex flex-column' : 'fixed-grid--between'"
    >

      <div
        v-for="(year, idx) in years.slice(0, limit)"
        class="px-1 py-3"
      >

        <card-year2022
          v-if="hasExam(year)"
          :item="getExam(year)"
          :color="color"
          class="mb-5"
        ></card-year2022>

        <card-p-d-f-year2022
          v-else
          :subject_path="subject_path"
          :color="color"
          :year="year"
          class="mb-8"
        ></card-p-d-f-year2022>
      </div>

      <div
        v-if="limit<=years.length && $vuetify.breakpoint.xs"
        @click="limit< years.length ? limit=years.length : limit = 4"
        class="d-flex justify-center mb-10 mt-3 align-center"
        style="cursor: pointer"
      >
        <v-icon size="18"
                :style="limit< years.length ? '' : 'transform: rotate(180deg) !important'"
        >
          {{mdiChevronDown}}
        </v-icon>
        <span class="caption ml-1 text-uppercase font-weight-medium" style="color: #6B7280 !important;">
          {{ limit < years.length ? `Mostra'n més` : `Mostra'n menys` }}
        </span>
      </div>

    </div>

    <v-row
      v-else
      no-gutters
      class="mb-5"
      style="width: 100%"
    >
      <v-col
        v-for="item in 12"
        class="mt-6 mb-1"
        cols="12" sm="3" md="2"
        :class="$vuetify.breakpoint.xs ? 'pr-3' : ''"
      >
        <div class="px-2">

        <v-skeleton-loader
          height="70px"
          width="100%"
          type="image"
          style="background-color: rgba(255,255,255,0.26) !important; box-shadow: 0 12px 24px rgb(2 30 84 / 10%) !important;"
          class="rounded-lg"
        ></v-skeleton-loader>
        </div>
      </v-col>
    </v-row>


  </div>
</template>

<script>
import {mdiChevronDown} from '/src/assets/mdi.json'

import CardYear2022 from "./YearButton";
import CardPDFYear2022 from "./YearPDFButton";

export default {
  components: {CardYear2022, CardPDFYear2022},
  props: {
    propsData: {
      type: Object | null,
      required: true
    },
    color: {
      type: String,
      default: "#466eb5"
    },
    subject_path: {
      type: String
    }
  },
  data() {
    return {
      mdiChevronDown: mdiChevronDown,
      limit: 12,
      exams: [],
      refresh: 0,
      years: [2025, 2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000]
    }
  },
  computed: {
    isMobile: function () {
      return this.$vuetify.breakpoint.name === 'sm' || this.$vuetify.breakpoint.name === 'xs'
    },
  },
  methods: {
    hasExam(year) {
      return this.exams.some(e => e.year === year)
    },
    getExam(year) {
      return this.exams.find(e => e.year === year)
    }
  },
  watch: {
    propsData: {
      immediate: true,
      async handler(val) {
        if (val) {

          this.exams = val.examsPerYear.sort((a, b) => a.year > b.year ? -1 : 1);
          this.limit = this.years.length;
          if (this.limit > 12)
            this.limit = 12
        }
      }
    },
    '$vuetify.breakpoint.name': {

      immediate: true,
      async handler(val) {

        switch (val) {
          case 'sm':
            this.limit = 10;
            break;
          case 'md':
            this.limit = 12;
            break;
          case 'xs':
            this.limit = 4;
            break;

          default:
            this.limit = 12;
            break;
        }

        this.refresh++;
      }
    }
  }
}
</script>

<style scoped>

/* for space-between style*/
.fixed-grid--between {
  grid-template-columns: repeat(auto-fit, 160px);
  display: grid;
  justify-content: space-between;
}

/* both should run fixed width elements equal to the declared in the container */
.grid-element {
  width: 160px;
}


.pressed {
  display: table-cell;
  position: relative;
  box-shadow: 0 0.14rem 0.25rem rgba(0, 0, 0, .16);
  width: 100vw;
  z-index: 100;
}

.tit {
  color: #333333;
  font-weight: 600;
  font-family: Rubik, sans-serif !important;
}

.v-slide-group >>> .v-slide-group__next, .v-slide-group >>> .v-slide-group__prev {
  margin-bottom: 40px;
}

</style>
